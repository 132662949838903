<template>
  <div v-if="!isEmpty(project)" class="project-detail__content card">
    <h3 class="mt-2 mb-4">Details</h3>
    <!-- Facilities -->
    <div class="row pb-4">
      <div class="col-12 md-col-6 mb-2">
        <h5 class="mb-1">Facilities</h5>
        <p class="data">
          <span v-if="project.projectFacilities.length < 1">No facility</span>
          <span v-else>
            <ul class="pl-3">
              <li
                v-for="facility in project.projectFacilities"
                :key="facility.id"
              >
                {{ facility.name }}
              </li>
            </ul>
          </span>
        </p>
      </div>
      <!-- Amenities -->
      <div class="col-12 md-col-6 mb-2">
        <h5 class="mb-1">Amenities</h5>
        <p class="data">
          <span v-if="project.projectAmenities.length < 1">No amenity</span>
          <span v-else>
            <ul class="pl-3">
              <li v-for="amenity in project.projectAmenities" :key="amenity.id">
                {{ amenity.name }}
              </li>
            </ul>
          </span>
        </p>
      </div>
    </div>
    <!-- Video -->
    <div v-if="!isEmpty(project.videoLink)" class="video">
      <video-embed
        css="embed-responsive-16by9"
        :src="project.videoLink"
      ></video-embed>
    </div>
    <!-- Site Map -->
    <div class="row mt-2 mb-4">
      <h4 class="mb-2">Site Plans</h4>
      <cool-light-box
        :items="projectSiteMapImages"
        :index="projectSiteMapIndex"
        @close="projectSiteMapIndex = null"
        :slideshow="false"
      >
      </cool-light-box>
      <swiper class="swiper col-12 d-flex" :options="swiperOption">
        <swiper-slide
          v-for="(siteMap, imageIndex) in projectSiteMapImages"
          :key="imageIndex"
          class="xl-col-2 lg-col-3 md-col-4 sm-col-4 col-6"
        >
          <div class="image-container">
            <div
              class="image cursor-pointer"
              @click="projectSiteMapIndex = imageIndex"
            >
              <img :src="siteMap" @error="$setAltPhoto" loading="lazy" />
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>

    <!-- Gallery -->
    <div v-if="projectGalleryImages.length > 0" class="row mt-2 mb-4">
      <h4 class="mb-2">Gallery Photos</h4>
      <cool-light-box
        :items="projectGalleryImages"
        :index="projectGalleryIndex"
        @close="projectGalleryIndex = null"
        :slideshow="false"
      >
      </cool-light-box>
      <swiper class="swiper col-12 d-flex" :options="swiperOption">
        <swiper-slide
          v-for="(gallery, imageIndex) in projectGalleryImages"
          :key="imageIndex"
          class="xl-col-2 lg-col-3 md-col-4 sm-col-4 col-6"
        >
          <div class="image-container">
            <div
              class="image cursor-pointer"
              @click="projectGalleryIndex = imageIndex"
            >
              <img :src="gallery" @error="$setAltPhoto" loading="lazy" />
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>

    <!-- Map Display -->
    <MglMap
      v-if="lngLat"
      class="col-12"
      :style="{ height: '300px' }"
      :accessToken="mapGl.accessToken"
      :mapStyle="mapGl.mapStyle"
      :center="lngLat"
      :zoom="16"
    >
      <MglMarker :coordinates="lngLat" color="red"></MglMarker>
    </MglMap>
    <!-- ============================================================= -->
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import { MglMap, MglMarker } from "vue-mapbox";

export default {
  components: {
    MglMap,
    MglMarker
  },
  mixins: [],
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isEmpty: isEmpty,

      // Lightbox and images
      projectSiteMapIndex: null,
      // projectSiteMapImages: [],
      projectGalleryIndex: null,
      // projectGalleryImages: [],

      // swiper
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 5,
        freeMode: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },

      mapGl: {
        accessToken: process.env.VUE_APP_MAPBOX_PK,
        mapStyle: "mapbox://styles/mapbox/streets-v11"
      }
    };
  },
  computed: {
    projectSiteMapImages() {
      let siteMapImages = this.project.siteMap.map((item) => {
        return this.$getFileURL(item);
      });

      return [...siteMapImages];
    },
    projectGalleryImages() {
      if (this.project.gallery.length > 0) {
        let galleryImages = this.project.gallery.map((item) => {
          return this.$getFileURL(item);
        });
        return [...galleryImages];
      }
      return [];
    },
    lngLat() {
      if (
        this._.isEmpty(this.project?.longitude) ||
        this._.isEmpty(this.project?.latitude)
      ) {
        return null;
      }
      return [this.project.longitude, this.project.latitude];
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-detail__content {
  @mixin outerSpacing {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;

    @media #{$breakpoint-down-sm} {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @include outerSpacing();

  .image-container {
    @include flex(row, center, center);
    border: 1px solid #ddd;
    position: relative;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    .image {
      display: block;
      width: 100%;
      img {
        @include image(cover);
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
      }
    }
  }

  .video {
    padding: 40px 10px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
  }

  .embed-responsive {
    max-width: 500px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: white;
  }
}
</style>
